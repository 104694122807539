<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="app-wrapper">
    <BaseNavigation />
    <DividerNavigation />
    <div class="p-2 mx-auto">
      <CardPost v-if="!forum.isLoading && !!forum.data" :forum="forum.data" />
      <div v-if="forum.isLoading" class="d-flex justify-content-center mb-1">
        <b-spinner label="Loading..." variant="danger" />
      </div>
      <section v-if="!forum.isLoading && !!forum.data">
        <div
          v-for="comment in forum.data.comments"
          :key="comment.id"
          class="d-flex"
        >
          <b-img
            :src="imgProfile(comment)"
            height="50"
            width="50"
            rounded="circle"
            class="mr-1"
          />
          <div class="flex: 1;" style="width: 100%">
            <b-card
              class="shadow-none border p-1"
              no-body
              style="background-color: #eaeaea"
            >
              <div class="d-flex">
                <div>
                  <div class="font-weight-bold">
                    {{ comment.orang.orang_nama_lengkap }}
                  </div>
                  <div class="mt-25" v-html="comment.forcomment_content" />
                </div>
                <!-- <div class="ml-auto ">
                  <feather-icon
                    icon="AlertCircleIcon"
                    size="20"
                    stroke-width="2"
                    class="text-danger"
                    @click="isModal = true"
                  />
                </div> -->
              </div>
            </b-card>
          </div>
        </div>
        <div>
          <b-form @submit.prevent>
            <validation-observer ref="addCommentToForum">
              <validation-provider
                #default="{ errors }"
                name="Comment Forum"
                rules="required"
              >
                <quill-editor
                  v-model="newComment"
                  :options="snowOption"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <b-button
                class="mt-1"
                type="submit"
                variant="outline-danger"
                block
                @click="validationFormCommentForum"
              >
                Kirim
              </b-button>
            </validation-observer>
          </b-form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
/* eslint-disable global-require */
import { BSpinner, BImg, BCard, BButton } from "bootstrap-vue";
import BaseNavigation from "@/components/Base/BaseNavigation.vue";
import DividerNavigation from "@/components/Base/DividerNavigation.vue";
import CardPost from "@/components/Forum/CardPost.vue";
import { quillEditor } from "vue-quill-editor";
import apis from "@/api";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    BaseNavigation,
    DividerNavigation,
    BSpinner,
    CardPost,
    BImg,
    BCard,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    BButton,
  },
  data() {
    return {
      required,
      kripIcon: require("@/assets/images/icons/icon-stethoscope.png"),
      rekomendasiIcon: require("@/assets/images/icons/icon-documents.png"),
      keanggotaanIcon: require("@/assets/images/icons/icon-idcard.png"),
      noProfilePicture: require("@/assets/images/logo/logo-new-idi.png"),
      examplePicStudiKasus: require("@/assets/images/pages/Studi_Kasus_Contoh.jpeg"),
      forum: {
        isLoading: false,
        data: null,
      },
      noAvatar: require("@/assets/images/avatars/no-avatar.png"),
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
        theme: "snow",
        placeholder: "Silahkan tulis komentar disini ...",
      },
      newComment: "",
    };
  },
  created() {
    this.fetchForum();
  },
  computed: {
    imgProfile(item) {
      return (item) => {
        if (item.orang.orang_file_photo_resmi.includes("https")) {
          return item.orang.orang_file_photo_resmi;
        } else {
          return this.noProfilePicture;
        }
      };
    },
  },
  methods: {
    validationFormCommentForum() {
      this.$refs.addCommentToForum.validate().then((success) => {
        if (success) {
          this.addComment();
        }
      });
    },
    fetchForum() {
      this.forum.isLoading = true;
      apis.forum
        .getById(this.$route.params.id)
        .then(({ data }) => {
          this.forum.data = data;
          this.forum.isLoading = false;
        })
        .catch(() => {});
    },
    addComment() {
      this.$store.commit("app/UPDATE_LOADING_BLOCK", true);
      apis.forum
        .addComment(this.$route.params.id, {
          comment: this.newComment,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Berhasil menambahkan komentar baru",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.newComment = "";
          this.fetchForum();
        })
        .catch((error) => {
          this.errorHandler(
            error,
            "gagal menambahkan komentar, silahkan coba lagi nanti"
          );
        })
        .finally(() => {
          this.$store.commit("app/UPDATE_LOADING_BLOCK", false);
        });
    },
  },
};
</script>
